.Headcounts {
}

.HeadcountBox {
    margin: 10px 30px;
    padding: 10px;
    border-radius: 5px;
    background-color: teal;
    color: white;
}

.HeadcountBox:hover {
    cursor: pointer;
}

.HeadcountRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}