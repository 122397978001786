.Logo {
    /* background-color: white; */
    padding: 3px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-flow: row;
}

.Logo img {
    height: 100%;
}

.Logo h6 {
    align-items: center;
    color: white; 
    line-height: 38px;
    font-size: 1rem;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    text-decoration: none;
}



