.Login {
    max-width: 50%;
    margin: 100px auto;
    font-size: .9rem;
    text-align: left;
}

@media only screen and (min-width: 768px) {
    .Login {
        max-width: 500px;
    }
  }