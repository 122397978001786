.SessionSignIns {
    width: 100%; 
    overflow-x: auto;
}

.Title {
    /* text-align: center; */
    background-color:#091455;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;
}

.TitleInfo {

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.TitleSelects {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.RollSelect {
    /* width: 33%; */
    /* max-width: 150px; */
    /* min-width: 30%; */
    flex-grow: 1;
    margin: 0 2px;
    font-size: .7rem;
    font-weight: bold;
}

.RollSelect select {
    font-size: .6rem;
}


.TitleText {
    /* padding-left: 10px; */
    min-width: 150px;
}

.Table {
    font-size: .5rem;
    /* font-weight: bold; */
    width: 100%;
    /* table-layout: fixed; */
}


.Row:hover {
    cursor: pointer;
}

.Row {
    font-weight: bold;
    border: none;
}
.SelectedRow {
    border: 3px solid pink;
    background-color: none;
    background-image: linear-gradient(45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3)),
    linear-gradient(-45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3));
    background-size: 30px 30px;

  }

.RowCheckedIn {
    background-color: #a2d061;
    color: white;
}
.RowCheckedOut {
    background-color: #4e216a;
    color: white;
}
.RowAbsent {
    background-color: #3fa1c6;
    color: white;
}

.Row td {
    width: 10px;
}

.CheckInBadge {
    padding: 5px 4px;
    background-color: #091455;
    border-radius: 5px;
    color: white;
}

.DetailRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 5px;
}

.DetailKey {
    font-weight: bold;
    min-width: 150px;
    color: #88dab5;
}