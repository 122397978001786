.Filters {
    position: absolute;
    /* left: 0; */
    margin: auto;
    width: auto;
    box-sizing: border-box;
    text-align: left;
    font-size: 0.8rem; 
}

.HeaderItem:hover {
    cursor: pointer;
}

.Header {
    position: relative;
}