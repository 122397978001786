.PhotoUpload {
    box-sizing: border-box;
    text-align: center;
    font-size: 0.8rem;
    width: 100%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px #504f4f; */
    padding: 0;
    /* margin: 0; */
}

.Img {
    max-width: 100%;
}
@media only screen and (min-width: 550px) {
    .Img {
        max-width: 300px;
    }
    .PhotoUpload {
        max-width: 400px;
    }
}
