.MasterData {
    background-color: white;
    /* width: %; */
    margin: auto;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}

.Button {
    margin: 1px 1px 0 1px;
}
