.Container {
    text-align: left;
}

.TopDrawer {
    width: 100%;
    /* margin: 10px auto; */
    margin: 0 auto;
    left: 0;
    top: 0;
    z-index: 200;
    border-bottom: 2px solid lightgrey; 
    border-radius: 5px;
    box-shadow: 1px 1px 5px #b1aeae;
    background-color: white;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: auto;

}

.TopDrawerOpen {
    box-shadow: none;
}

.Row {
    display: flex;
    /* height: auto; */
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: flex-start;
    overflow-x: auto;
    font-size: 0.8rem;
    /* padding: 5px; */
    /* margin: 10px; */
    /* overflow: hidden; */
}

.Open {
    max-height: 750px;
    transition: max-height .6s ease-out;
    padding: 5px;

}

.Close {
    max-height: 0px;
    transition: max-height 0.4s ease-out;
}

.Button {
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    color: #464646;
    margin-top: -1px;
    margin-right: 5px;
    border: 1px solid lightgrey;
    box-shadow: 1px 1px 5px #b1aeae;
    font-size: 1.0rem;
    min-width: 210px;
    height: 35px;
    /* box-sizing: border-box; */
}

.Button:hover {
    cursor: pointer;

}

.Title:hover {
    cursor: pointer;
}