.ProviderProfile {
    position: relative;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}

.Row span {
    font-weight: bold;
}

.Item {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}