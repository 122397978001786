.App {
  text-align: center;

  
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Attempt to override form.io error classes */
.error {
  color: white !important; 
  background-color: rgb(201, 137, 137) !important;
  padding: 10px;
  border-radius: 3px;
}

/* Override full-calendar on hover class */
.fc-event:hover {
    cursor: pointer;
}

/* .formio-enrolment-form-table .table {
  table-layout: fixed;
  width: 100%;
} */

/* .formio-error-wrapper {
  background-color: white !important; 
  color: white;
} */

/* .is-invalid {
  border-width: 2px !important;
} */
