.Notification {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 5px auto;
    padding: 2px;
    border-bottom: 1px solid rgb(231, 229, 229);
    /* border-radius: 5px; */
}

.Notification:hover {
    cursor: pointer;
}

.NotificationHeader {
    margin-top: 20px;
}

.NotificationTitle {
    font-size: .85rem;
    /* padding: 5px; */
    /* font-weight: bold; */

}

.PendingBadge {
    padding: 2px 8px;
    /* margin: 0 10px; */
    font-size: 0.8rem;
    border-radius: 50%;
    /* font-weight: bold; */
    background: rgb(170, 170, 170);
    color: white;
}
.ActionBadge {
    padding: 2px 8px;
    /* margin: 0 10px; */
    font-size: 0.8rem;
    border-radius: 50%;
    /* font-weight: bold; */
    background: rgb(197, 33, 33);
    color: white;
}

.ApprovedBadge {
    padding: 2px 8px;
    /* margin: 0 10px; */
    font-size: 0.8rem;
    border-radius: 50%;
    /* font-weight: bold; */
    background: rgb(128, 181, 118);
    color: white;
}