.AccountOwner {
    background-color: white;
    /* width: %; */
    margin: auto;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}

.Wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 25px auto;
    font-size: .8rem;
    text-align: left;
}

.Wrapper > div {
    margin: 10px 5px;
}
@media only screen and (min-width: 768px) {
    .Wrapper {
        max-width: 100vw;
    }

    .LeftBar {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }

    .Wrapper > div {
        margin: 10px 25px;
    }
}



.LeftBar {
    flex: 1 1 150px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 10px;
    max-height: 90vh;
    
    /* display: flex; */
    /* flex-flow: column wrap; */
}


.RightBar {
    flex: 1 1 150px;
}

.Content {
    flex: 5 1 450px;
    overflow-x: auto;
}

.Content > div {
    margin: 0 0 10px 0;
}


.Form {
    font-size: .8rem;
}

