.CentreLink {
    background-color: white;
    margin: auto;
    border: 1px solid lightgrey; 
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}

.Wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 15px auto;
    font-size: .8rem;
    text-align: left;
}

.LeftBar {
    flex: 1 1 150px;
    background-color: white;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px #504f4f; */
    padding: 10px;
    max-height: 90vh;
}

.Content {
    flex: 12 1 450px;
    overflow-x: auto;
}

.Wrapper > div {
    margin: 10px;
}

@media only screen and (min-width: 768px) {
    .Wrapper {
        max-width: 100vw;
    }

    .LeftBar {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }


}


.HeaderButtonRow {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}