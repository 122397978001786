.Banner {
    width: 100%;
    position: absolute;
    top: 56px;
    left: 0;
    margin: auto;
    /* height: 40px; */
    /* line-height: 40px; */
    /* background-color: #28a746; */
    /* color: white; */
    text-align: center;
}

.Hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    z-index: -100;
}

.Visible {
    visibility: visible;
    /* transition: opacity .1s ease-in; */
    opacity: 1;
    /* transition: opacity 2s linear; */
  }  