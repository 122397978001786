.Wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 25px auto;
    font-size: .8rem;
    text-align: left;
}

.Wrapper > div {
    margin: 10px 5px;
}
@media only screen and (min-width: 768px) {
    .Wrapper {
        max-width: 100vw;
    }

    .LeftBar {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }

    .Wrapper > div {
        margin: 10px 25px;
    }
}



.LeftBar {
    flex: 1 1 150px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 10px;
    max-height: 90vh;
    
    /* display: flex; */
    /* flex-flow: column wrap; */
}


.RightBar {
    flex: 1 1 150px;
}

.Content {
    flex: 5 1 450px;
    overflow-x: auto;
}

.Content > div {
    margin: 0 0 10px 0;
}


.Form {
    background-color: white;
    margin: auto;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
}

/* @media (min-width: 1200px) {
    .Approvals {
        width: 1000px;
        left: calc(50% - 500px);
    }
} */

.Link {
    color: blue;
}

.Link:hover {
    cursor: pointer;
}

.Notification {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 5px auto;
    padding: 5px;
    border-bottom: 1px solid rgb(231, 229, 229);
    /* border-radius: 5px; */
}

.Notification:hover {
    cursor: pointer;
}

.NotificationTitle {
    font-size: .85rem;
    /* padding: 5px; */
    /* font-weight: bold; */

}

.NotificationBadge {
    padding: 2px 8px;
    /* margin: 0 10px; */
    font-size: 0.8rem;
    border-radius: 50%;
    /* font-weight: bold; */
    background: rgb(226, 68, 68);
    color: white;
}