.Header {
    padding-left: 1rem;
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(1, 168, 229, 0.164);
    color: white;
    height: 75px;
    /* border-bottom: 1px solid white; */
}



.Icon {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-Items: center;
}

.Body {
    /* margin: 1rem auto; */
    margin-top: 2rem;
    max-width: '3000px'; 
    padding: 0 0.5rem;
}

@media only screen and (min-width: 768px) {
    .Body {
        padding: 0 2rem;
        margin-top: 56px;
    }
}