.Cognito {
    box-sizing: border-box;
    text-align: left;
    font-size: 0.8rem;
    /* max-width: 60%; */
    margin: auto;
    background-color: white;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px #504f4f; */
    padding: 25px;
    box-sizing: border-box;
}
/* 
@media only screen and (min-width: 550px) {
    .Cognito {
        max-width: 350px;
    }
} */

.Form {
    display: flex;
    flex-direction: column;
}

.Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
}

.Label {
    min-width: 80px;
    font-weight: bold;

}

.Select {
    font-size: .8rem;
}