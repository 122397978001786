.QRScanner {
    box-sizing: border-box;
    text-align: center;
    font-size: 0.8rem;
    max-width: 90%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
}

@media only screen and (min-width: 550px) {
    .QRScanner {
        max-width: 400px;
    }
}