.Wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 10px auto;
    font-size: .8rem;
    text-align: left;
}

.Wrapper > div {
    margin: 10px 5px;
}


@media only screen and (min-width: 768px) {
    .Wrapper {
        max-width: 100vw;
    }

    .LeftBar {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }

    .Wrapper > div {
        margin: 10px 25px;
    }
}



.LeftBar {
    flex: 1 1 150px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 10px;
    max-height: 80vh;
    /* display: flex; */
    /* flex-flow: column wrap; */
}

.LeftBar > button {
    font-size: 0.8rem;
}

.RightBar {
    flex: 1 1 150px;
}

.FormColumn {
    flex: 5 1 450px;
    overflow-x: auto;
}

.FormColumn > div {
    margin: 0 0 10px 0;
}

.Form {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;  
    
}


.FormTitle {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;  
    padding: 10px;
    text-align: center;
    /* height: 40px; */
    /* text-transform: capitalize; */
}

/* 
.Enrol {
    max-width:95%;
    margin: 25px auto;
    font-size: .9rem;
    text-align: left;
} */

