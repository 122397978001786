.CentreRow {
    width: 100%;
    margin: auto;
    /* border: 1px solid #464646; */

    /* padding: 5px; */
}

.ScheduleView {
    /* background-color: black; */
    width: 100%;
    margin: auto;
    padding-top: 10px;
}

.SessionRow {
    /* margin: 5px; */
    box-shadow: 1px 1px 5px #b1aeae;
    border-radius: 4px;
}

.SessionTitle {
    width: 100%;
    background-color: rgb(99, 133, 174);
    color: white;
    padding: 8px;
    border-radius: 4px 4px 0 0;
    text-align: center;
    margin-bottom: 0;
}

.TrueFill {
    background-color: rgb(195, 239, 195);
    border: 1px solid white;
}